/** @jsx jsx */
import { jsx, Container, Box } from 'theme-ui';
import SectionHeader from 'components/section-header';
import Accordion from 'components/accordion/accordion';
const faqs = [
  {
    title: 'Why did you build this app ?',
    contents: (
      <div>
        As a wine lover, I wanted to maximize the enjoyment per dollar spent on a bottle of wine. Wine is expensive, life is short, and wine ratings are simply not useful in determining whether I will like a wine or not.
      </div>
    ),
  },
  {
    title: 'Who is this app for?',
    contents: (
      <div>
        This app is for wine lovers of all experience level who wish to document, analyze and improve their wine experiences.
        
      </div>
    ),
  },
  {
    title: `How does Avintageo differ from other wine apps?`,
    contents: (
      <div>
        There are several great wine apps out there, but they tend to fall be focused on purchasing a wine or procuring social opinions from others. 
        Avintageo is more of a complement to wine purchasing and social apps, since our focus is purely on the individual's wine experience and we have nothing to sell you.
      </div>
    ),
  },
  {
    title: `How many wines do you have?`,
    contents: (
      <div>
        We currently have around 10,000 wines in our database and this number will continue to grow. If there are any wines missing plesase add them through the app and we will get them in our system with speed.
      </div>
    ),
  },
];
export default function Faq() {
  return (
    <section sx={{ variant: 'section.faq' }} id="faq">
      <Container>
        <SectionHeader
          title="Frequently asked questions"
          slogan="About Avintageo"
        />
        <Box
          sx={{
            display: 'flex',
            width: ['100%', null, null, '650px', '745px'],
            flexDirection: 'column',
            mx: 'auto',
            my: -4,
          }}
        >
          <Accordion items={faqs} />
        </Box>
      </Container>
    </section>
  );
}
